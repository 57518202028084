<template>
  <div class="products">
    <el-dialog
    title="單據詳情"
    :visible.sync="dialogVisible"
    width="30%"
    :before-close="handleClose">
    <div class="invoice-content">
      {{ $t('Invoice ID') }}： 053126941<br/>
      {{ $t('Created Time') }}： 2024-07-02 11:15:5<br/>
      {{ $t('Meals') }}： <br/>
      冬蔭公蝦湯 HK$ 58 <br/>
      芒果糯米飯 HK$ 38<br/><br/>
      {{ $t('Paid') }}： $96
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">關閉</el-button>
      <el-button type="primary" @click="dialogVisible = false">重印</el-button>
    </span>
  </el-dialog>

    <div class="page-content">
      <div class="content-header">
        <h2>{{ $t('單據') }}</h2>
      </div>
      <div class="invoices-list">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">{{ $t('Invoice ID') }}： 053126941</h5>
            <p class="card-text">{{ $t('Created Time') }}： 2024-07-02 11:15:5</p>
            <p class="card-text">{{ $t('Meals Number') }}： 1</p>
            <p class="card-text">{{ $t('Paid') }}： $96</p>
            <a href="#" @click="openDialog" class="btn btn-primary">{{ $t('Invoice Detail') }}</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">{{ $t('Invoice ID') }}： 142835118</h5>
            <p class="card-text">{{ $t('Created Time') }}： 2024-07-02 11:23:4</p>
            <p class="card-text">{{ $t('Meals Number') }}： 2</p>
            <p class="card-text">{{ $t('Paid') }}： $120</p>
            <a href="#" @click="openDialog" class="btn btn-primary">{{ $t('Invoice Detail') }}</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">{{ $t('Invoice ID') }}： 203133739</h5>
            <p class="card-text">{{ $t('Created Time') }}： 2024-07-02 12:05:10</p>
            <p class="card-text">{{ $t('Meals Number') }}： 3</p>
            <p class="card-text">{{ $t('Paid') }}： $150</p>
            <a href="#" @click="openDialog" class="btn btn-primary">{{ $t('Invoice Detail') }}</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">{{ $t('Invoice ID') }}： 321451662</h5>
            <p class="card-text">{{ $t('Created Time') }}： 2024-07-02 15:15:0</p>
            <p class="card-text">{{ $t('Meals Number') }}： 1</p>
            <p class="card-text">{{ $t('Paid') }}： $40</p>
            <a href="#" @click="openDialog" class="btn btn-primary">{{ $t('Invoice Detail') }}</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">{{ $t('Invoice ID') }}： 457735033</h5>
            <p class="card-text">{{ $t('Created Time') }}： 2024-07-02 16:03:50</p>
            <p class="card-text">{{ $t('Meals Number') }}： 1</p>
            <p class="card-text">{{ $t('Paid') }}： $45</p>
            <a href="#" @click="openDialog" class="btn btn-primary">{{ $t('Invoice Detail') }}</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">{{ $t('Invoice ID') }}： 496007703</h5>
            <p class="card-text">{{ $t('Created Time') }}： 2024-07-02 16:13:5</p>
            <p class="card-text">{{ $t('Meals Number') }}： 1</p>
            <p class="card-text">{{ $t('Paid') }}： $50</p>
            <a href="#" @click="openDialog" class="btn btn-primary">{{ $t('Invoice Detail') }}</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">{{ $t('Invoice ID') }}： 991244061</h5>
            <p class="card-text">{{ $t('Created Time') }}： 2024-07-02 16:17:12</p>
            <p class="card-text">{{ $t('Meals Number') }}： 1</p>
            <p class="card-text">{{ $t('Paid') }}： $60</p>
            <a href="#" @click="openDialog" class="btn btn-primary">{{ $t('Invoice Detail') }}</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">{{ $t('Invoice ID') }}： 730672899</h5>
            <p class="card-text">{{ $t('Created Time') }}： 2024-07-02 16:20:15</p>
            <p class="card-text">{{ $t('Meals Number') }}： 5</p>
            <p class="card-text">{{ $t('Paid') }}： $552</p>
            <a href="#" @click="openDialog" class="btn btn-primary">{{ $t('Invoice Detail') }}</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">{{ $t('Invoice ID') }}： 195070751</h5>
            <p class="card-text">{{ $t('Created Time') }}： 2024-07-02 16:30:22</p>
            <p class="card-text">{{ $t('Meals Number') }}： 3</p>
            <p class="card-text">{{ $t('Paid') }}： $35</p>
            <a href="#" @click="openDialog" class="btn btn-primary">{{ $t('Invoice Detail') }}</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">{{ $t('Invoice ID') }}： 903413945</h5>
            <p class="card-text">{{ $t('Created Time') }}： 2024-07-02 16:43:2</p>
            <p class="card-text">{{ $t('Meals Number') }}： 2</p>
            <p class="card-text">{{ $t('Paid') }}： $70</p>
            <a href="#" @click="openDialog" class="btn btn-primary">{{ $t('Invoice Detail') }}</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">{{ $t('Invoice ID') }}： 995912978</h5>
            <p class="card-text">{{ $t('Created Time') }}： 2024-07-02 16:55:4</p>
            <p class="card-text">{{ $t('Meals Number') }}： 1</p>
            <p class="card-text">{{ $t('Paid') }}： $35</p>
            <a href="#" @click="openDialog" class="btn btn-primary">{{ $t('Invoice Detail') }}</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">{{ $t('Invoice ID') }}： 675213759</h5>
            <p class="card-text">{{ $t('Created Time') }}： 2024-07-02 17:02:15</p>
            <p class="card-text">{{ $t('Meals Number') }}： 1</p>
            <p class="card-text">{{ $t('Paid') }}： $65</p>
            <a href="#" @click="openDialog" class="btn btn-primary">{{ $t('Invoice Detail') }}</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">{{ $t('Invoice ID') }}： 875008015</h5>
            <p class="card-text">{{ $t('Created Time') }}： 2024-07-03 11:00:0</p>
            <p class="card-text">{{ $t('Meals Number') }}： 1</p>
            <p class="card-text">{{ $t('Paid') }}： $40</p>
            <a href="#" @click="openDialog" class="btn btn-primary">{{ $t('Invoice Detail') }}</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">{{ $t('Invoice ID') }}： 457735033</h5>
            <p class="card-text">{{ $t('Created Time') }}： 2024-07-03 11:15:15</p>
            <p class="card-text">{{ $t('Meals Number') }}： 1</p>
            <p class="card-text">{{ $t('Paid') }}： $50</p>
            <a href="#" @click="openDialog" class="btn btn-primary">{{ $t('Invoice Detail') }}</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">{{ $t('Invoice ID') }}： 590826851</h5>
            <p class="card-text">{{ $t('Created Time') }}： 2024-07-03 18:00:0</p>
            <p class="card-text">{{ $t('Meals Number') }}： 1</p>
            <p class="card-text">{{ $t('Paid') }}： $75</p>
            <a href="#" @click="openDialog" class="btn btn-primary">{{ $t('Invoice Detail') }}</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">{{ $t('Invoice ID') }}： 668187094</h5>
            <p class="card-text">{{ $t('Created Time') }}： 2024-07-03 18:30:2</p>
            <p class="card-text">{{ $t('Meals Number') }}： 1</p>
            <p class="card-text">{{ $t('Paid') }}： $100</p>
            <a href="#" @click="openDialog" class="btn btn-primary">{{ $t('Invoice Detail') }}</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">{{ $t('Invoice ID') }}： 765119747</h5>
            <p class="card-text">{{ $t('Created Time') }}： 2024-07-03 15:00:1</p>
            <p class="card-text">{{ $t('Meals Number') }}： 2</p>
            <p class="card-text">{{ $t('Paid') }}： $120</p>
            <a href="#" @click="openDialog" class="btn btn-primary">{{ $t('Invoice Detail') }}</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">{{ $t('Invoice ID') }}： 830838515</h5>
            <p class="card-text">{{ $t('Created Time') }}： 2024-07-03 12:35:8</p>
            <p class="card-text">{{ $t('Meals Number') }}： 1</p>
            <p class="card-text">{{ $t('Paid') }}： $80</p>
            <a href="#" @click="openDialog" class="btn btn-primary">{{ $t('Invoice Detail') }}</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">{{ $t('Invoice ID') }}： 976703400</h5>
            <p class="card-text">{{ $t('Created Time') }}： 2024-07-04 16:00:9</p>
            <p class="card-text">{{ $t('Meals Number') }}： 1</p>
            <p class="card-text">{{ $t('Paid') }}： $110</p>
            <a href="#" @click="openDialog" class="btn btn-primary">{{ $t('Invoice Detail') }}</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">{{ $t('Invoice ID') }}： 595585107</h5>
            <p class="card-text">{{ $t('Created Time') }}： 2024-07-04 16:00:9</p>
            <p class="card-text">{{ $t('Meals Number') }}： 1</p>
            <p class="card-text">{{ $t('Paid') }}： $110</p>
            <a href="#" @click="openDialog" class="btn btn-primary">{{ $t('Invoice Detail') }}</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">{{ $t('Invoice ID') }}： 155925267</h5>
            <p class="card-text">{{ $t('Created Time') }}： 2024-07-04 16:00:9</p>
            <p class="card-text">{{ $t('Meals Number') }}： 1</p>
            <p class="card-text">{{ $t('Paid') }}： $110</p>
            <a href="#" @click="openDialog" class="btn btn-primary">{{ $t('Invoice Detail') }}</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">{{ $t('Invoice ID') }}： 801870140</h5>
            <p class="card-text">{{ $t('Created Time') }}： 2024-07-04 16:00:9</p>
            <p class="card-text">{{ $t('Meals Number') }}： 1</p>
            <p class="card-text">{{ $t('Paid') }}： $110</p>
            <a href="#" @click="openDialog" class="btn btn-primary">{{ $t('Invoice Detail') }}</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">{{ $t('Invoice ID') }}： 248897454</h5>
            <p class="card-text">{{ $t('Created Time') }}： 2024-07-05 16:00:9</p>
            <p class="card-text">{{ $t('Meals Number') }}： 1</p>
            <p class="card-text">{{ $t('Paid') }}： $110</p>
            <a href="#" @click="openDialog" class="btn btn-primary">{{ $t('Invoice Detail') }}</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">{{ $t('Invoice ID') }}： 546894960</h5>
            <p class="card-text">{{ $t('Created Time') }}： 2024-07-05 16:00:9</p>
            <p class="card-text">{{ $t('Meals Number') }}： 1</p>
            <p class="card-text">{{ $t('Paid') }}： $110</p>
            <a href="#" @click="openDialog" class="btn btn-primary">{{ $t('Invoice Detail') }}</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">{{ $t('Invoice ID') }}： 228992735</h5>
            <p class="card-text">{{ $t('Created Time') }}： 2024-07-05 16:00:9</p>
            <p class="card-text">{{ $t('Meals Number') }}： 1</p>
            <p class="card-text">{{ $t('Paid') }}： $110</p>
            <a href="#" @click="openDialog" class="btn btn-primary">{{ $t('Invoice Detail') }}</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">{{ $t('Invoice ID') }}： 571639510</h5>
            <p class="card-text">{{ $t('Created Time') }}： 2024-07-05 16:00:9</p>
            <p class="card-text">{{ $t('Meals Number') }}： 1</p>
            <p class="card-text">{{ $t('Paid') }}： $110</p>
            <a href="#" @click="openDialog" class="btn btn-primary">{{ $t('Invoice Detail') }}</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">{{ $t('Invoice ID') }}： 976703400</h5>
            <p class="card-text">{{ $t('Created Time') }}： 2024-07-06 16:00:9</p>
            <p class="card-text">{{ $t('Meals Number') }}： 1</p>
            <p class="card-text">{{ $t('Paid') }}： $110</p>
            <a href="#" @click="openDialog" class="btn btn-primary">{{ $t('Invoice Detail') }}</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">{{ $t('Invoice ID') }}： 165047459</h5>
            <p class="card-text">{{ $t('Created Time') }}： 2024-07-06 16:00:9</p>
            <p class="card-text">{{ $t('Meals Number') }}： 1</p>
            <p class="card-text">{{ $t('Paid') }}： $110</p>
            <a href="#" @click="openDialog" class="btn btn-primary">{{ $t('Invoice Detail') }}</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">{{ $t('Invoice ID') }}： 408407404</h5>
            <p class="card-text">{{ $t('Created Time') }}： 2024-07-06 16:00:9</p>
            <p class="card-text">{{ $t('Meals Number') }}： 1</p>
            <p class="card-text">{{ $t('Paid') }}： $110</p>
            <a href="#" @click="openDialog" class="btn btn-primary">{{ $t('Invoice Detail') }}</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">{{ $t('Invoice ID') }}： 254211184</h5>
            <p class="card-text">{{ $t('Created Time') }}： 2024-07-07 16:00:9</p>
            <p class="card-text">{{ $t('Meals Number') }}： 1</p>
            <p class="card-text">{{ $t('Paid') }}： $110</p>
            <a href="#" @click="openDialog" class="btn btn-primary">{{ $t('Invoice Detail') }}</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">{{ $t('Invoice ID') }}： 291929099</h5>
            <p class="card-text">{{ $t('Created Time') }}： 2024-07-07 16:00:9</p>
            <p class="card-text">{{ $t('Meals Number') }}： 1</p>
            <p class="card-text">{{ $t('Paid') }}： $110</p>
            <a href="#" @click="openDialog" class="btn btn-primary">{{ $t('Invoice Detail') }}</a>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">{{ $t('Invoice ID') }}： 466306696</h5>
            <p class="card-text">{{ $t('Created Time') }}： 2024-07-07 16:00:9</p>
            <p class="card-text">{{ $t('Meals Number') }}： 1</p>
            <p class="card-text">{{ $t('Paid') }}： $110</p>
            <a href="#" @click="openDialog" class="btn btn-primary">{{ $t('Invoice Detail') }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapState, mapActions } from 'vuex';
import Common from '@/lib/common';

export default {
  name: 'Seats',
  mounted(){
  },
  data(){
    return {
      dialogVisible: false,
    }
  },
  methods:{
    openDialog(){
      this.dialogVisible = true;
    }
  },
  computed: mapState({
   langcode: state => state.langcode,
   apiUrl: state => state.apiUrl,
 }),
}
</script>

<style scoped lang="scss">
.content-header{
  display: flex;
  h2{
    flex: 1;
  }
  .action-button{
    margin-right: 20px;
  }
}
.invoices-list{
  display: flex;
  flex-wrap: wrap;
  margin-left: 20px;
  .card{
    margin-bottom: 30px;
    margin-right: 20px;
    .btn-primary{
      margin-right: 30px;
    }
  }
}
</style>
